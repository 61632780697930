<template>

    <div id="confirmation" class="modal fade show" style="display: block;" aria-modal="true" role="dialog">
        <div class="modal-dialog modal-confirm modal-dialog-centered">
            <div class="modal-content">
             
                <div class="modal-header"><h5 class="modal-title">  {{header}}</h5><button type="button" class="close" data-bs-dismiss="modal" aria-hidden="true" @click="$emit('cancel')">×</button></div>

                <div class="modal-body">
                    <p>{{ question }}</p>
                </div>
                <div class="modal-footer justify-content-center">
                    <button type="button" class="btn btn-primary" @click="$emit('confirm')">{{confirm}}</button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="$emit('cancel')">{{cancel}}</button>

                </div>
            </div>
        </div>
    </div>
    <div class="modal-backdrop fade show"></div>


</template>

<script>
    import { defineComponent } from "vue";

    export default defineComponent({
        props: ["question", "header", "cancel", "confirm"],
        emits: ["confirm", "cancel"]
    });

</script>

<style>
.modal-body {
    text-align: justify; /* or justify, left, right */
    padding: 10px; /* Adjust as needed */
    line-height: 1.5; /* Spacing between lines */
    font-size: 16px; /* Adjust text size */
}
</style>