<template>
   <div></div>
        <div class="header-section" v-if="facebook || twitter || linkedin || instagram || youtube || blog">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-8 col-md-12">
                        <div class="header-widget">
                           <!-- <ul>
                                <li>
                                    <i class="icofont-location-pin"></i>
                                    {{ concatAddress || '28/A Street, New York City' }}
                                </li>
                                <li>
                                    <i class="icofont-phone"></i>
                                    Call Us:
                                    <a href="tel:+1010612457">
                                        {{ Phone || '+1010612457' }}
                                    </a>
                                </li>
                            </ul>
                            -->
                        </div>
                    </div>   
                    <div class="col-md-4">
                        <div class="header-social text-end">
                            <ul>
                                <li class="d-inline-block" v-if="facebook">
                                    <a :href="splitLinkUrlsofFacebook" target="_blank"><i class="icofont-facebook"></i></a>
                                </li>
                                <li class="d-inline-block" v-if="twitter">
                                    <a :href="splitLinkUrlsoftwitter" target="_blank"><i class="icofont-twitter"></i></a>
                                </li>
                                <li class="d-inline-block" v-if="linkedin">
                                    <a :href="splitLinkUrlsoflinkedin" target="_blank"><i class="icofont-linkedin"></i></a>
                                </li>
                                <li class="d-inline-block" v-if="instagram">
                                    <a :href="splitLinkUrlsofinstagram" target="_blank"><i class="icofont-instagram"></i></a>
                                </li>
                                <li class="d-inline-block" v-if="youtube">
                                    <a :href="splitLinkUrlsofyoutube" target="_blank"><i class="icofont-youtube-play"></i></a>
                                </li>
                                <li class="d-inline-block" v-if="blog">
                                    <a :href="splitLinkUrlsofblog" target="_blank"><i class="icofont-blogger"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="navbar-area">
            <div class="main-nav">
                <div class="container">
                    <nav class="navbar navbar-expand-md navbar-light">
                        <a class="navbar-brand" href="/">
                            <img v-if="companylogourl==''" src="../../../../../assets/img/logo.png" class="main-logo" alt="logo">

                            <img v-else="companylogourl!==''" :src="companylogourl" class="main-logo" alt="logo">
                        </a>
                        <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                            <ul class="navbar-nav ms-auto">
                                <li class="nav-item">


                                    <router-link to="/" tag="a" class="nav-link">Home</router-link>
                                </li>
                                <!--
        <li class="nav-item">
            <router-link to="/Aboutus" tag="a" class="nav-link">About</router-link>
        </li>
        -->


                                <li class="nav-item">
                                    <a class="nav-link dropdown-toggle">
                                        Services
                                    </a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item" v-for="workType in workTypeList" :key="workType.id">
                                            <a @click="SelectedService(workType.id)" class="nav-link">{{ workType.Name }}</a>
                                        </li>
                                    </ul>
                                    <!--<ul v-else class="dropdown-menu">
                <li class="nav-item">
                    <span class="nav-link disabled">Enter a zipcode to view services</span>
                </li>
            </ul>-->
                                </li>

                                <!--  <li class="nav-item">
            <router-link to="/contactus" tag="a" class="nav-link">Contact</router-link>
        </li> -->
                            </ul>
                            <div class="navbar-button btn2" v-if="LoginASpuserid === '' || LoginASpuserid === null">
                                <a href="javascript:;" class="nav-link" @click="navigateToLogin">Login</a>
                            </div>
                            <div class="navbar-button" v-if="fromSignUp === '' || fromSignUp === null">
                                <a href="javascript:;" class="nav-link" @click="navigateToSignUp">Sign Up</a>
                            </div>
                            <div class="navbar-button" v-if="LoginASpuserid !== '' && LoginASpuserid !== null">
                                <a class="nav-link" @click="Userlogout">Log Out</a>
                            </div> &nbsp; &nbsp;


                            <div class="custom-navbar-button" v-if="LoginASpuserid !== '' && LoginASpuserid !== null">
                                <a href="#" class="custom-nav-link">My Profile</a>
                                <div class="custom-dropdown-content">
                                    <a href="#" @click.prevent="performAction('My Profile')">My Profile</a>
                                    <a href="#" @click="performAction('Log out')">Log out</a>
                                </div>
                            </div>

                        </div>
                    </nav>
                </div>
            </div>
        </div>      
     </template>
<script>
    import side from '@/views/AuthorizedUserView/AuthorizeUserLayout/SideMenu.vue'
    import frontEndService from '@/Services/frontEndService.js'
    export default {
        name: 'HeaderVue',
        components: {
            side
        },
        props: ["LoginASpuserid","fromSignUp"],
        emits: ["openSignUpdmodel", "openLoginModel", "Userlogout","SelectedService","navigateToLogin","navigateToSignUp"],
        data() {
            return {
                companyLogo: '',
                IsOpenLeftMenu: false,
                IsOpenRightMenu: false,
                companyname: localStorage.getItem('companyName'),
                contentlist: null, companyId: localStorage.getItem('companyId'), userid: localStorage.getItem('comp-userId'),
                workTypeList: null,
                Bannerlist: null, websitelist: null, footerlist: null, brandinglist: null, companylogourl: localStorage.getItem('companyLogo'),
                faviconUrl:localStorage.getItem('faviconUrl'),

                Phone: '', concatAddress: '',
                isrefreshed: true,
                refreshL: localStorage.getItem('isrefreshSignIn'),
                facebook: '', twitter: '', linkedin: '', instagram: '',
                ZipbasedLocation:localStorage.getItem("SerchLocation")
                
            }
        },
        computed: {
            
            splitLinkUrlsoftwitter() {
                return this.twitter.split(",").map(url => {
                    if (!url.startsWith("http://") && !url.startsWith("https://")) {
                        return `http://${url}`;
                    }
                    return url;
                });
            },
            splitLinkUrlsofFacebook() {
                debugger
                return this.facebook.split(",").map(url => {
                    if (!url.startsWith("http://") && !url.startsWith("https://")) {
                        return `http://${url}`;
                    }
                    return url;
                });
            },
            splitLinkUrlsoflinkedin() {
                return this.linkedin.split(",").map(url => {
                    if (!url.startsWith("http://") && !url.startsWith("https://")) {
                        return `http://${url}`;
                    }
                    return url;
                });
            },
            splitLinkUrlsofinstagram() {
                return this.instagram.split(",").map(url => {
                    if (!url.startsWith("http://") && !url.startsWith("https://")) {
                        return `http://${url}`;
                    }
                    return url;
                });
            },
            splitLinkUrlsofyoutube() {
                return this.youtube.split(",").map(url => {
                    if (!url.startsWith("http://") && !url.startsWith("https://")) {
                        return `http://${url}`;
                    }
                    return url;
                });
            },
            splitLinkUrlsofblog() {
                return this.blog.split(",").map(url => {
                    if (!url.startsWith("http://") && !url.startsWith("https://")) {
                        return `http://${url}`;
                    }
                    return url;
                });
            },
        },
       
        created() {
            /*this.getDynamicContents();*/

            this.getDynamicContents();
            this.getHeaderWorkTypes();
            this.getLoginuserdata();
            this.currentRouteId = this.$route.params.id;
            //this.isRefreshed();
            //if (this.LoginASpuserid === '' || this.LoginASpuserid === null) {

            //}
            
        },
        methods: {
            performAction(data){

                debugger
                if(data == 'My Profile'){

                    this.Profile();
                }
                else if(data == 'Log out'){

                    this.Userlogout();
                }
            },
            Profile()
            {
                 this.$emit("Profile");

            },

            Userlogout(){
                this.$emit("Userlogout");
            },
            navigateToLogin() {
                this.$emit("navigateToLogin");
            },
            navigateToSignUp() {
                this.$emit("navigateToSignUp");
            },
             getDynamicContents(){
                   debugger
                frontEndService.getHomepageData(this.companyId, this.userid).then(response => {
                       
                    
                    if(response!=null){
                    this.brandinglist=JSON.parse(response.data[0].brandingdata)

                    console.log("",JSON.parse(response.data[0].brandingdata))
                           
                        this.companylogourl = this.brandinglist[0].logourl
                        this.faviconUrl = this.brandinglist[0].FaviconUrl


                        this.websiteList = JSON.parse(response.data[0].websiteData);
                        if (this.websiteList && this.websiteList.length > 0) {
                            this.facebook = this.websiteList[0].facebook.split(',')[0];
                            this.twitter = this.websiteList[0].twitter.split(',')[0];
                            this.linkedin = this.websiteList[0].linkedin.split(',')[0];
                            this.instagram = this.websiteList[0].instagram.split(',')[0];
                            this.youtube = this.websiteList[0].Youtube.split(',')[0];
                            this.blog = this.websiteList[0].blog.split(',')[0];
                        }
                    }   
                   
                   
                })
            },


            aboutus() {

                this.$Options()
            },
            getHeaderWorkTypes() {
                   
                frontEndService.getAllWorkTypes(this.companyId).then(response => {
                       
                    console.log('Wo_data', response);
                    if (response != null) {
                           
                        this.workTypeList = response.data
                    }
                })
            },
            SelectedService(id) {
                debugger
                this.$emit("SelectedService",id);
                //this.currentRouteId = id; 
                //this.$router.push({ path: `/cartServices/${id}` });
            },
            getLoginuserdata() {
                   
                frontEndService.getuserInfo(this.companyId, this.LoginASpuserid).then(res => {

                    console.log(res);


                    this.UserInformation = JSON.parse(res.data[0].JsonData)
                    this.address = this.UserInformation.UserBasicInfo[0].Address;

                    this.Phone = this.UserInformation.UserBasicInfo[0].PhoneNumber;
                    this.ShippingAddress = this.UserInformation.UserOrderAddressInfo[0].ShippingStreet;
                    this.ShippingCity = this.UserInformation.UserOrderAddressInfo[0].ShippingCity;

                    this.concatAddress = this.ShippingAddress + ', ' + this.ShippingCity;
                   


                })
            },
            
        }
    }
</script>
<style>
    /* Basic styling for the navbar button */
    .custom-navbar-button {
        position: relative;
        display: inline-block;
    }

    /* Style for the link */
    .custom-nav-link {
        background-color: #4CAF50;
        color: white;
        padding: 16px;
        font-size: 16px;
        text-decoration: none;
        border-radius: 4px;
    }

    /* Style the dropdown content */
    .custom-dropdown-content {
        display: none;
        position: absolute;
        background-color: #f9f9f9;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
    }

        /* Style the links inside the dropdown */
        .custom-dropdown-content a {
            color: black;
            padding: 12px 16px;
            text-decoration: none;
            display: block;
        }

            /* Change color of links on hover */
            .custom-dropdown-content a:hover {
                background-color: #f1f1f1;
            }

    /* Show the dropdown menu on hover */
    .custom-navbar-button:hover .custom-dropdown-content {
        display: block;
    }

    .custom-navbar-button:hover .custom-nav-link {
        background-color: #3e8e41;
    }
    .custom-nav-link {
    background-color: #4CAF50;
    color: white;
    padding: 10px 27px;
    font-size: 16px;
    text-decoration: none;
    border-radius: 5px;
}
a.custom-nav-link:hover {
    color: #fff !important;
}
</style>
